import classnames from 'classnames/bind'
import { gsap } from 'gsap'
import React, { RefObject, useRef, useState } from 'react'

import {
  useIsHover,
  useIsomorphicLayoutEffect,
  useMeasure,
  useOnResize,
  useRefs,
  useSpring,
} from '@unlikelystudio/react-hooks'
import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import ImageWithPlaceHolder from '~/components/ImageWithPlaceholder'
import Link from '~/components/Link'
import Ratio from '~/components/Ratio'
import WrapperWithLink from '~/components/WrapperWithLink'

import { useStyle } from '~/providers/StyleProvider'

import getAriaLabelProp from '~/utils/get-aria-label-prop'

import css from './styles.module.scss'
import { PushLinksProps, SideImageState, SPRING_CONFIG } from './types'

const cx = classnames.bind(css)

const DEFAULT_ALT = 'Push links image'

function PushLinks({ className, sections }: PushLinksProps) {
  const sectionKeys = Object.keys(sections ?? [])
  const [firstSection] = sectionKeys

  const [sideImage, setSideImage] = useState<SideImageState | null>({
    section: firstSection,
    link: 0,
  })
  const [isHover, mouseEvents] = useIsHover()
  const [widthArray, setWidthArray] = useState<number[]>([])
  const [sectionActive, setSectionActive] = useState<string>(firstSection)
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-9-10', color: 'gold' })
  const linkStyle = useStyle({ textPreset: 'cta-GT-18-16' })

  const links = sectionKeys?.reduce((acc, section) => {
    acc.push(
      ...sections?.[section]?.map((link) => ({
        ...link,
        section,
      })),
    )
    return acc
  }, [])

  const [{ slideIndex, setSlideIndex, ...sliderState }, setSliderState] =
    useSliderState()
  const boundRef = useRef<HTMLDivElement>(null)
  const sliderContainerRef = useRef<HTMLDivElement>(null)
  const linksWrapperRef = useRef<HTMLDivElement>(null)
  const linksRefs: RefObject<RefObject<HTMLDivElement>[]> = useRefs(
    links?.length,
  )
  const { width } = useMeasure(boundRef)

  useIsomorphicLayoutEffect(() => {
    setLinksWidthValues()
  }, [])

  const setLinksWidthValues = () => {
    const widthValues = linksRefs?.current
      ?.filter((ref) => ref)
      ?.map((ref) => ref.current.getBoundingClientRect()?.width)

    setWidthArray(widthValues)
  }

  const [setLinksWrapperSpring] = useSpring({
    x: 0,
    config: SPRING_CONFIG,
    onUpdate: ({ x }) => {
      gsap.set(linksWrapperRef.current, {
        force3D: true,
        x: -x,
      })
    },
  })

  const onSlideChange = (sliderIndex: number) => {
    if (links[sliderIndex]?.section !== sectionActive)
      setSectionActive(links[sliderIndex]?.section)
    setSlideIndex(sliderIndex)
    translateLinks(sliderIndex)
  }

  const translateLinks = (sliderIndex: number) => {
    let xValue = 0

    //@ts-ignore
    for (const [index, value] of widthArray.entries()) {
      if (index === sliderIndex) break
      xValue = xValue + value
    }

    setLinksWrapperSpring({ x: xValue })
  }

  useOnResize(setLinksWidthValues)

  const hoveredLink = sections?.[sideImage?.section]?.[sideImage?.link]?.link

  return (
    <section className={cx(className, css.PushLinks)}>
      <div ref={boundRef} className={css.bounds} />
      <div ref={sliderContainerRef} className={css.sliderContainer} />
      <div className={cx(gridStyle, css.wrapper)}>
        <div className={css.links}>
          {sectionKeys?.map((section) => (
            <div key={section} className={cx(css.section)}>
              <p
                className={cx(titleStyle, css.title, {
                  isHover: isHover && sideImage.section !== section,
                })}>
                {section}
              </p>
              {sections[section]?.map(({ link }, id: number) => (
                <Link
                  key={`${section}link${id}`}
                  className={cx(linkStyle, css.link, { isHover })}
                  {...mouseEvents}
                  onMouseEnter={(e) => {
                    mouseEvents.onMouseEnter(e)
                    setSideImage({ section, link: id })
                  }}
                  {...link}
                />
              ))}
            </div>
          ))}
        </div>
        <WrapperWithLink
          className={css.imageWrapper}
          {...getAriaLabelProp(hoveredLink?.children as string)}
          href={hoveredLink?.href}>
          <div className={cx({ imageWrapper: Boolean(!hoveredLink?.href) })}>
            <Ratio ratio={935 / 700}>
              {(cn) => (
                <ImageWithPlaceHolder
                  className={cn}
                  objectFit="cover"
                  layout="fill"
                  sizesFromBreakpoints={[
                    { breakpoint: 'md', columns: 8 },
                    { columns: 5 },
                  ]}
                  {...sections?.[sideImage?.section]?.[sideImage?.link]
                    ?.landscape}
                  alt={
                    sections?.[sideImage?.section]?.[sideImage?.link]?.landscape
                      ?.alt ?? DEFAULT_ALT
                  }
                />
              )}
            </Ratio>
          </div>
        </WrapperWithLink>
        <Slider
          className={css.slider}
          dragProps={{
            bounds: {
              ...sliderState.defaultBounds,
              left: sliderState.defaultBounds.left - width,
            },
          }}
          setSliderState={setSliderState}
          customSliderRef={sliderContainerRef}
          onSlideIndexChange={(index) => onSlideChange(index)}
          snap>
          {links?.map(({ link, portrait }, index) => (
            <Link
              key={`pushLink${index}`}
              className={css.card}
              {...getAriaLabelProp(link?.children as string)}
              {...link}>
              <Ratio ratio={260 / 347}>
                {(cn) => (
                  <ImageWithPlaceHolder
                    className={cn}
                    objectFit="cover"
                    layout="fill"
                    sizesFromBreakpoints={[
                      { breakpoint: 'md', columns: 8 },
                      { columns: 5 },
                    ]}
                    {...portrait}
                    alt={portrait?.alt ?? DEFAULT_ALT}
                  />
                )}
              </Ratio>
            </Link>
          ))}
        </Slider>
        <div className={css.sliderLinksWrapper}>
          <div className={css.titleSection}>
            {sectionKeys?.map((section, index) => (
              <p
                key={`section${index}`}
                className={cx(titleStyle, css.titleEntry, {
                  active: sectionActive === section,
                })}>
                {section}
              </p>
            ))}
          </div>

          <div ref={linksWrapperRef} className={css.sliderLinks}>
            {links?.map(({ link }, index) => (
              <Link
                key={`pushLink${index}`}
                ref={linksRefs?.current?.[index]}
                className={cx(css.sliderLink, {
                  isActive: slideIndex === index,
                })}
                {...getAriaLabelProp(link?.children as string)}
                {...link}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

PushLinks.defaultProps = {}

export default PushLinks
