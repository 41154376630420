import { ImageProps } from '~/components/Image'
import { LinkProps } from '~/components/Link'

export interface SideImageState {
  section: string
  link: number
}

interface LinkWithImage {
  link: LinkProps
  portrait: ImageProps
  landscape: ImageProps
}

export interface PushLinksSections {
  [key: string]: LinkWithImage[]
}

export interface PushLinksProps {
  className?: string
  sections: PushLinksSections[]
}

export const SPRING_CONFIG = {
  interpolation: 'basic',
  friction: 10,
  precision: 0.1,
  perfectStop: true,
}
